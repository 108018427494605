
<!-- 台风路径 -->
<template>
  <div  class="page_box">
    <iframe class="ifBox" src="/tflj.html" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: "tflj",
  data() {
    return {

    }
  },
  created() {
  },
  mounted() {

  },
  methods: {


  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.page_box{
  // padding-top: 10px;
  // overflow: hidden;
  position: relative;
}
.ifBox{
  // position: absolute;
  width: 100%;
  height: 100%;
    // top: -7%;
    // left: -18%;
}
</style>