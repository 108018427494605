
<!-- 全省预报 -->
<template>
  <div class="startPage ">
  <!-- <div class="content_box theme_bg"> -->
    <div class="Location"  v-show="showIsTab">
     <!--  <div class="title_name"  v-if="ToActivePath.name === 'TianQiYuBao'">
       <svg t="1688456025346" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5776" width="18" height="18"><path d="M839.8 281.4c-17.9-42.3-43.5-80.3-76.1-112.9-32.6-32.6-70.6-58.2-112.9-76.1-43.8-18.6-90.4-28-138.3-28-48 0-94.5 9.4-138.3 27.9-42.3 17.9-80.3 43.5-112.9 76.1-32.6 32.6-58.2 70.6-76.1 112.9-18.5 43.8-27.9 90.3-27.9 138.3 0 81.3 57.4 196.2 170.5 341.5C410.3 867.3 494.1 952 495 952.8c4.6 4.7 11 7.3 17.5 7.3 6.6 0 12.9-2.6 17.5-7.3 0.8-0.8 84.6-85.6 167.2-191.6C810.4 616 867.8 501.1 867.8 419.7c0-47.9-9.4-94.5-28-138.3zM510.7 555.9c-71.9 0-130.4-58.5-130.4-130.4s58.5-130.4 130.4-130.4 130.4 58.5 130.4 130.4-58.5 130.4-130.4 130.4z" p-id="5777" fill="#ffffff"></path></svg>
        <span  @click="cityShowFun">&nbsp;{{CityInfo.city}}气象&nbsp;</span>
        <svg t="1688456204890" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6841" width="18" height="18"><path d="M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z" p-id="6842" fill="#ffffff"></path></svg>
        <div class="tips" v-show="isShowTips">
          点击这里查看各县天气预报
          <a href="javaScript:;" @click="isShowTips = false">x</a>
        </div>
        <transition  name="moveCartoon">
          <city-picker v-show="isCityPicker" @selectCity="selCityCodeFun" ref="cityPicker"></city-picker>
        </transition>
      </div>
      <div class="title_name" v-else>邯郸市气象</div> -->
      <!-- <van-tabs color="#CAE3E0" class="prediction_tab" v-model="activeYbConfig" @change="tab" >
        <van-tab v-for="item of predictionList" :key="item.name" :title="item.title"></van-tab>
      </van-tabs> -->
      <div style="width:100%;position: relative;">
        <div style="border-bottom:1px solid #F7F7F7;right:0;bottom:0;height:100%;" class="heng"></div>
        <div class="top">
    <div v-for="(item,index) in predictionList" :key="index" @click="()=>{i=index;tab(index)}" :class="i==index?'dian':''">
{{ item.title }}
    </div>
</div>
        <div style="border-bottom:1px solid #F7F7F7;left:0;bottom:0;height:100%" class="heng"></div>
      </div>


    </div>
    <!-- <div style="height: 10%;"></div> -->
    <!-- <keep-alive> -->
      <component @showTab="showTab" :CityInfo="CityInfo"  :class="['qsyb','tflj'].includes(ToActivePath.name)?'qsyb childrens':'childrens'" :is="ToActivePath.name"  :ref="ToActivePath.name"></component>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import TianQiYuBao from './TianQiYuBao.vue'
import cityPicker from '@/components/my-city-picker'
import yzyb from './other/yzyb.vue'
import qsyb from './other/qsyb.vue'
import tflj from './other/tflj.vue'
import ldjc from './other/ldjc.vue'
import wmyb from './other/wmyb.vue'

export default {
  components: {
    TianQiYuBao,
    cityPicker,
    yzyb,
    qsyb,
    tflj,
    ldjc,
    wmyb,
  },
  name: "startPages",
  data() {
    return {
      i:0,
      isShowTips:true,
      isCityPicker:false,
      showIsTab:true,
      CityInfo:{},
      ToActivePath:{},
      activeYbConfig: '天气预报',
      predictionList: [
        {title:'天气预报', name:'TianQiYuBao' },
        // {title:'乡镇预报',name:'yzyb'},
        {title:'全省预报',name:'qsyb'},
        {title:'台风路径',name:'tflj'},
        // {title:'雷电监测',name:'ldjc'},
        {title:'雾和交通',name:'wmyb'},
      ],
    }
  },
  created() {
    this.ToActivePath = this.predictionList[0];
  },
  mounted() {

  },
  methods: {
    showTab(isshow){
      this.showIsTab = isshow;
    },
    tab(index){
      this.ToActivePath = this.predictionList[index];
    },
    // 打开选项
    cityShowFun(){
      this.isCityPicker = !this.isCityPicker;
    },
    // 选择城市
    selCityCodeFun(e){
      this.isCityPicker = false;
      this.CityInfo = e;
      this.$refs.TianQiYuBao.selCityData(this.CityInfo)
    }

  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>

/* 进入动画 */
.moveCartoon-enter-active {
    animation: move 0.3s;
}

/* 离开动画 */
.moveCartoon-leave-active {
    animation: move 0.3s reverse;
    
}
@keyframes move {
  0% {transform: translate(-90%,-50%) scale(0,0);opacity: 0;}
  40% {transform: translate(-70%,-40%) scale(0.5,0.3);opacity: 0.1;}
  100% { transform: translate(-50%,0%);}
}

.startPage{
  width: 100%;
  // height: 100vh;
    background-color:#95D5FB ;
  background-size: 100% 100%;
  // background-image: url('~@/assets/images/shbg.jpg');
  background-image: url('~@/assets/images/bg_4.jpg');
  background-repeat: no-repeat;
    background-size: 100%;
  // overflow-x: auto;
}

.tips{
  margin-left: 10px;
  background-color: rgba($color: #2A73B2, $alpha: 0.9);
  padding: 3px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 10px;
  position: relative;
  &::before{
    content: " ";
    position: absolute;
    left: -2%;
    top: 24%;
    display: block;
    width: 0.015rem;
    height: 0.015rem;
    background-color: rgba($color: #2A73B2, $alpha: 0.9);
    transform: rotate(45deg);
    z-index: -10;
  }
  a{
    position: absolute;
    right: -7%;
    top: -20%;
    width: 15px;
    height: 15px;
    color: rgba($color: #000000, $alpha: 0.7);
    border-radius: 50%;
    background-color: rgba($color: #fff, $alpha: 0.7);
  }
}
/*定位、日期*/
.Location {
  width: 100%;
  // height: 80px;//
  text-align: center;
  box-sizing: border-box;
  // position: fixed;
  z-index: 10;
  padding-top:5px;
  box-sizing: border-box;
  // background-color: var(--bg_background_color);
  .title_name {
    position: relative;
    font-size: 16px;
    margin: 10px 0;
    padding: 8px 9px 0px;
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: flex-start;
  }
}
.childrens{
  // height: calc(100vh - 80px);
  overflow: auto;
}
.qsyb{
  height: calc(100vh - 80px);
}
// .tranfrom_Y{
//   transform:translateY(calc(100vh - (100vh - 10px)));//meishu
// }
.page_box{
  // height: calc(100vh - 80px);//
  position: relative;
}
.prediction_tab {
  ::v-deep .van-tabs__wrap {
    height: 30px;
    .van-tab {
      border-bottom: 2px solid rgba($color: #fff, $alpha: 0.1);
      padding: 0 5px;
    }
  }

  ::v-deep .van-tabs__nav {
    background-color: transparent;

    .van-tab__text {
      color: rgba($color: #fff, $alpha: .8);
    }

    .van-tabs__wrap--scrollable .van-tab {
      padding: 0;
    }
  }
}

.top{
    display: flex;
    justify-content: space-around;
    padding:0 10px;
    box-sizing: border-box;
    // width:calc(100% - 20px);
    div{
        padding:10px 0px;
        width:25%;
        border-radius:5px 5px 0 0 ;
        border-bottom:1px solid #F7F7F7;
    }
}
// .heng{
//     width:120%;
//     height:1px;
//     background: #999;
//     position: relative;
//     top:-2px;
//     left: -20px;
//     z-index:1;
// }
.heng{
  width:10px;
  position: absolute;
}
.dian{
    z-index:2;
    // background: rgb(24,51,89);
    border: 1px solid #F7F7F7 !important;
    border-bottom: none !important;
    color:#66CBFF
}
</style>